import * as React from 'react';
import Layout from '@layouts';
import { Link } from 'gatsby';

const AboutPage = () => {
	return (
		<Layout>
			<h1>About</h1>
			<p>
				Dolor consectetur id dolor consectetur ipsum et ut dolor labore. Cillum
				reprehenderit sit fugiat proident ea adipisicing. Tempor adipisicing
				irure veniam ad fugiat fugiat excepteur aliquip. Exercitation mollit
				incididunt eu amet ullamco excepteur nulla magna esse.
			</p>
		</Layout>
	);
};

export default AboutPage;
